import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container } from 'react-bootstrap';
import Product from '../components/Product';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Paginate from '../components/Paginate';

import Meta from '../components/Meta';
import ProductCarousel from '../components/ProductCarousel';

// import TextCarousel from '../components/TextCarousel';
// import ScrollTwo from '../components/ScrollTwo';
// import Scroll from '../components/Scroll';
import { listProducts } from '../redux/actions/productActions';
import { Link } from 'react-router-dom';
import Swiper from '../components/Swiper';

const HomeScreen = ({ match }) => {
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages } = productList;

  const keyword = match.params.keyword;
  const pageNumber = match.params.pageNumber || 1;

  const newJumbo = true;

  useEffect(() => {
    dispatch(listProducts(keyword, pageNumber));
  }, [dispatch, keyword, pageNumber]);

  return (
    <>
      <Meta title={'Welcome to Maison Envie | Home'}></Meta>

      {!keyword ? (
        newJumbo ? (
          <Swiper></Swiper>
        ) : (
          <Container>
            <ProductCarousel></ProductCarousel>
          </Container>
        )
      ) : (
        <Container>
          <Link to='/' className='btn btn-light'>
            Go Back
          </Link>
        </Container>
      )}

      <Container>
        <div className='product_headline'>
          <h6>
            <center>{'Hand Made Candles'}</center>
          </h6>
          <h1>
            <center>{!keyword ? 'New Arrivals' : 'Search Results'}</center>
          </h1>
        </div>
        {loading ? (
          <Loader></Loader>
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <>
            <Row>
              {products.map((product, index) => {
                return (
                  <Col sm={12} md={6} lg={4} xl={4} key={index}>
                    <Product product={product}></Product>
                  </Col>
                );
              })}
            </Row>

            <Paginate
              page={page}
              pages={pages}
              keyword={keyword ? keyword : ''}
            ></Paginate>
          </>
        )}
      </Container>
      {/* <TextCarousel></TextCarousel>
      <Scroll></Scroll>
      <ScrollTwo></ScrollTwo> */}
    </>
  );
};

export default HomeScreen;
