import {
  ADD_USER_TO_EMAIL_LIST_REQUEST,
  ADD_USER_TO_EMAIL_LIST_SUCCESS,
  ADD_USER_TO_EMAIL_LIST_FAIL,
  ADD_USER_TO_EMAIL_LIST_RESET,
} from '../constants/emailConstants';

export const addUserToListReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_USER_TO_EMAIL_LIST_REQUEST:
      return { ...state, loading: true };

    case ADD_USER_TO_EMAIL_LIST_SUCCESS:
      return { loading: false, success: true };

    case ADD_USER_TO_EMAIL_LIST_FAIL:
      return { loading: false, error: action.payload };

    case ADD_USER_TO_EMAIL_LIST_RESET:
      return {};

    default:
      return state;
  }
};
