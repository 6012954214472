// rafce
import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../redux/actions/userActions';
import SearchBox from '../components/SearchBox';
import { Route } from 'react-router-dom';
import { ReactComponent as YourSvg } from './../assets/images/lucid_logo_svg_one.svg';
import Toast from './Toast';

const Header = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const logoutHandler = (e) => {
    e.preventDefault();
    dispatch(logout());
  };

  const numOfItems = cartItems.reduce((acc, item) => acc + item.qty, 0);
  return (
    <header>
      <Toast></Toast>
      <Navbar
        // bg='light'
        // bg='dark'
        // variant='dark'
        expand='lg'
        collapseOnSelect
        className='nav_bar_wrapper'
      >
        <Container>
          <LinkContainer to='/'>
            {/* <div></div> */}
            <YourSvg />
            {/* <Navbar.Brand>
              <YourSvg />
            </Navbar.Brand> */}
            {/* <Navbar.Brand>Maison Envie Co.</Navbar.Brand> */}
          </LinkContainer>

          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Route
              render={({ history }) => <SearchBox history={history} />}
            ></Route>
            <Nav className='ms-auto'>
              <LinkContainer
                to='/cart'
                // style={{ maxWidth: '75px' }}
              >
                <Nav.Link>
                  <i className='fas fa-shopping-cart'></i> Cart
                  <span> {numOfItems && `(${numOfItems})`}</span>
                </Nav.Link>
              </LinkContainer>

              {userInfo ? (
                <NavDropdown
                  title={userInfo.name}
                  id='username'
                  // style={{ maxWidth: '75px' }}
                >
                  <LinkContainer to='/profile'>
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    LogOut
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <LinkContainer to='/login'>
                  <Nav.Link>
                    <i className='fas fa-user'></i> Sign In
                  </Nav.Link>
                </LinkContainer>
              )}

              {userInfo && userInfo.isAdmin && (
                <NavDropdown
                  title={'Admin'}
                  id='adminMenu'
                  // style={{ maxWidth: '75px' }}
                >
                  <LinkContainer to='/admin/userlist'>
                    <NavDropdown.Item>Users</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/productlist'>
                    <NavDropdown.Item>Products</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/orderlist'>
                    <NavDropdown.Item>Orders</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
