import React, { useState } from 'react';
import { Col, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import FormContainer from '../components/FormContainer';
import { savePaymentMethod } from '../redux/actions/cartActions';
import CheckoutSteps from '../components/CheckoutSteps';

const PaymentScreen = ({ history }) => {
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;
  if (!shippingAddress) {
    history.push('/shipping');
  }
  // const [paymentMethod, setPaymentMethod] = useState('Paypal');
  const [paymentMethod, setPaymentMethod] = useState(
    cart.paymentMethod || 'Paypal'
  );

  const dispatch = useDispatch();

  const onSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(savePaymentMethod(paymentMethod));
    history.push('/placeorder');
  };
  return (
    <FormContainer>
      <CheckoutSteps step_1 step_2 step_3></CheckoutSteps>
      <center>
        <h1>Payment Method</h1>
      </center>
      <Form onSubmit={onSubmitHandler}>
        <Form.Group className='payment_method'>
          <Form.Label as='legend'>Select Method</Form.Label>

          <Col>
            <Form.Check
              type='radio'
              label='Paypal or Credit Card'
              id='paypal'
              name='paymentMethod'
              value='Paypal'
              checked
              onChange={(e) => {
                setPaymentMethod(e.target.value);
              }}
            ></Form.Check>
            {/* <Form.Check
              type='radio'
              label='Stripe'
              id='stripe'
              name='paymentMethod'
              value='Stripe'
              onChange={(e) => {
                setPaymentMethod(e.target.value);
              }}
            ></Form.Check> */}
          </Col>
        </Form.Group>

        <center>
          <Button
            type='submit'
            variant='primary'
            className='envie_button keikei_button'
          >
            Continue
          </Button>
        </center>
      </Form>
    </FormContainer>
  );
};

export default PaymentScreen;
